<template>
    <div class="ucenter">
        <van-nav-bar
                :border="false"
                :placeholder="true"
                :fixed="true"
                :left-text="$t('我的团队')"
                left-arrow
                @click-left="onClickLeft"
        >
        </van-nav-bar>
        <div class="inc-tabs">
            <van-tabs
                    class="ui-tabs"
                    v-model="active"
                    @click="onClick"
                    v-if="agent_list.length > 0"
            >
                <van-tab
                        v-for="(item, index) in agent_list"
                        :key="`agent_tab${index}`"
                        :title="$t(item.name)"
                        :name="item.id"
                >
                    <template #title>
                        <div class="tm-tab-title">
                            {{$t(item.name)}}
                        </div>
                    </template>
                </van-tab>
            </van-tabs>
            <div class="page-main">
                <van-pull-refresh
                    v-model="refreshing"
                    @refresh="onRefresh"
                    :pulling-text="$t('下拉即可刷新')"
                    :loosing-text="$t('释放即可刷新')"
                    :loading-text="$t('加载中')"
            >
                <van-empty v-show="isEmpty" :description="$t('没有记录')"/>
                <van-list
                        v-show="!isEmpty"
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="getData"
                >
                <div class="rew-panel">
                    <div class="rew-cell" v-for="(item, index) in data" :key="index">
                        <div class="rew-icon"><img class="ro-logo" src="../../assets/pictures/avatar-default.png"></div>
                        <div class="flex_bd">
                            <div class="rew-title">{{ item.username }}</div>
                            <div class="rew-time" style="display: flex">
                                {{ item.created_at }}
                            </div>
                        </div>
                        <div class="flex_right">
                            <div v-if="active == 1">{{ item.country + item.phone }}</div>
                        </div>
                    </div>
                </div>
                    <template slot="finished" v-if="data.length == 0">
                        <div class="empty-cell">
                            <img src="../../assets/pictures/empty.png" alt="">
                            <p>{{$t('没有更多了')}}</p>
                        </div>
                    </template>
                </van-list>
            </van-pull-refresh>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                active: 1,
                show: false,
                agent_list: [],
                data: [],
                loading: false,
                finished: false,
                refreshing: false,
                isEmpty: false,
                page: 0,
                size: 20,
            };
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1);
            },
            onClick(name, title) {
                this.onRefresh();
            },
            getData() {
                let url = this.$api_url.get_team_user + "?page=" + this.page + "&level=" + this.active;
                this.$axios
                    .get(url)
                    .then((res) => {
                        // console.log(res.data.agent_list)
                        if (res.data.code == 0) {
                            this.page += 1;
                            if (res.data.agent_list.length > 0) {
                                this.agent_list = res.data.agent_list;
                            }
                            if (this.data.length == 0) {
                                this.data = res.data.data;
                            } else {
                                this.data = this.data.concat(res.data.data);
                            }
                            if (res.data.data.length <= 0) {
                                this.finished = true;
                            }
                        } else {
                            this.finished = true;
                        }
                    })
                    .catch((err) => {
                        this.finished = true;
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                        this.loading = false;
                        this.refreshing = false;
                        this.isEmpty = this.data.length === 0;
                    });
            },
            onRefresh() {
                this.data = [];
                this.page = 0;
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.getData();
            },
        },
        mounted() {
        },
    };
</script>
<style scoped>
    .ucenter ::v-deep .van-nav-bar{
        background-color: #1a2331;
    }
    .rew-cell{
        padding: 10px 0;
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .rew-icon {
        margin-right: 10px;
    }
    .rew-icon img{
        width: 36px;
        height: 36px;
    }
    .rew-title{
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 5px;
    }
    .rew-time{
        opacity: .6;
    }
    .rew-cell{
        border-radius: 10px;
        margin-bottom: 15px;
        background-color: #1a2331;
        padding: 15px;
    }






</style>